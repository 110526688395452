module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-facebook-multi-pixels/gatsby-browser.js'),
      options: {"0":{"dev":true,"alias":"245515273200465","pixelId":"245515273200465","viewContent":true,"pageView":true},"1":{"dev":true,"alias":"621567405155650","pixelId":"621567405155650","viewContent":true,"pageView":true},"3":{"dev":true,"alias":"367452938001070","pixelId":"367452938001070","viewContent":true,"pageView":true},"4":{"dev":true,"alias":"1983283265078177","pixelId":"1983283265078177","viewContent":true,"pageView":true},"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-88020844-38"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":false,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jackson Curnonwatch","short_name":"Jackson","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a5d23cdc7251c41ea566d2c740e7c471"},
    }]
